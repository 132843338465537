import React, {useState} from 'react';
import {Button, ButtonProps} from '@amzn/awsui-components-react';
import {ConfirmationModal} from '../bulk-override/confirmation-modal';
import {EventType} from '../operational-dashboard/view-operational-dashboard';

export interface ButtonWithConfirmationProps {
    label: string;
    variant: ButtonProps.Variant;
    disabled: boolean;
    promptHeader: string;
    prompt: string;
    confirmPhrase: string;
    additionalInfo?: string;
    onConfirm: () => void;
    showCancel?: boolean;
    showInput?: boolean;
    onclickAdditional?: () => Promise<void>;
    eventType?: EventType;
    modelGroupName?: string;
    updateModelGroupName?: (string) => void;
    defaultForecastStartDateForGeneration?: string;
    updateForecastStartDate?: (string) => void;
    modelConfigOverride?: string;
    updateModelConfigOverride?: (string) => void;
}

export function ButtonWithConfirmation(props: ButtonWithConfirmationProps) {
    const defaultProps = {
        showCancel: props.showCancel ?? true,
        showInput: props.showInput ?? true,
    };
    props = {...props, ...defaultProps};
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    return (
        <React.Fragment>
            <Button
                data-testid="button"
                disabled={props.disabled}
                variant={props.variant}
                onClick={async () => {
                    setVisible(true);

                    if (props.onclickAdditional) {
                        setLoading(true);
                        await props.onclickAdditional();
                        setLoading(false);
                    }
                }}
            >
                {props.label}
            </Button>
            <ConfirmationModal
                visible={visible}
                promptHeader={props.promptHeader}
                prompt={props.prompt}
                confirmPhrase={props.confirmPhrase}
                additionalInfo={props.additionalInfo}
                onCancel={() => {
                    setVisible(false);
                }}
                onConfirm={() => {
                    setVisible(false);
                    props.onConfirm();
                }}
                loading={loading}
                showCancel={props.showCancel}
                showInput={props.showInput}
                eventType={props.eventType}
                modelGroupNameForGeneration={props.modelGroupName}
                updateModelGroupName={props.updateModelGroupName}
                defaultForecastStartDateForGeneration={props.defaultForecastStartDateForGeneration}
                updateForecastStartDate={props.updateForecastStartDate}
                modelConfigOverrideForGeneration={props.modelConfigOverride}
                updateModelConfigOverride={props.updateModelConfigOverride}
            />
        </React.Fragment>
    );
}
